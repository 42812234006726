import styled, { css } from "styled-components/macro"
import { BootstrapTable } from 'react-bootstrap-table'
import { Button, Form, Input, Space, Spin, } from "antd";
import { Modal } from "components"
import { Panel } from "react-bootstrap";
import { Flex, FlexRowSpaceBetween } from 'components'

export const FullWidth = styled.div`
  width: 100%;
`

export const FlexSpace = styled(Flex)`
  > :not(:first-child) {
    margin-left: ${props => {
    switch (props.size) {
      case 'large':
        return '32px'
      case 'small':
        return '8px'
      default:
        return '16px'
    }
  }};
  }
`

export const HeaderSection = styled(FlexRowSpaceBetween)`
  margin-bottom: 24px;
`

export const TableFirstChildTdHiddenOverflow = styled.div`
  table > tbody > tr > td:first-child {
    overflow: hidden;
    
  }
`

export const HeaderContainer = styled.div`
  padding-top: 2rem;
  margin-bottom: 3rem;
  margin-top: 20px;
  font-family: inherit;
  color: inherit;
`

export const Container = styled.div`
  margin: 10px;
`

export const HeaderTitle = styled.div`
  font-weight: 400;
  font-size: 26px;
  font-family: inherit;
  line-height: 1.1;
  color: inherit;
  margin-bottom: 25px;
`

export const IconButton = styled(Button)`
  vertical-align: unset;
`

const customSecondaryButton = {
  disabled: css`
    position: relative;
    overflow: hidden;
    cursor: default;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #FFF8;
    }
  `,
  hover: css`
    &:hover {
      background: #f5f7fa;
      border-color: #003b66;
    }
  `,
}

export const SecondaryButton = styled.div`
  cursor: pointer;
  padding: 5px 15px;
  white-space: nowrap;
  font-size: 14px;
  background: #dfe4e8;
  font-weight: 600;
  color: #003b66;
  height: min-content;
  align-self: center;
  border-radius: 5px;
  transition-duration: .25s;
  border: 1px solid transparent;
  ${props => props.disabled ? customSecondaryButton.disabled : customSecondaryButton.hover};
`

export const SettingsContainer = styled.div`
  display: flex;
  
  > button {
    margin: 0 10px;
  }
  .left {
    display: flex;
  }
  .right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    vertical-align: middle;
  }
  .checkbox-container {
    margin: auto 10px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const ScrollableListView = styled.div`
  &#main-content {
    height: 100vh;
  }
  > div:last-child {
    height: calc(100% - 150px);
    display: flex;
    padding-top: 20px;
    flex-direction: column;

    > div.snapshots:last-child:not(.row) {
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    > div.not-snapshots {
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      & > div, & > div > div, & > div > div > div, & > div > div > div > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
      }
    }

    .ant-space.ant-space-horizontal.ant-space-align-center.batch-operations {
      justify-content: flex-start;
      z-index: 3;
      border-radius: 2px;
      flex-direction: row;
      align-items: flex-start;
      position: absolute;
      left: 30px;
      top: 10px !important;
      padding: 5px;
      background-color: white;
      box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 35%);
      height: auto !important;
    }
  }
  > div.report-view-page {
    height: calc(100% - 124px);
  }
`

export const StickyScrollableTable = styled(BootstrapTable)`
  &.react-bs-table-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .react-bs-table {
      overflow-x: hidden;
    }

    .react-bs-container-body {

      * > tr:not(:hover) {
        .hover-actions-column div {
          display: none;
        }
      }

      .hover-actions-column {
        overflow: visible;
      }

      * > tr {
        &.order-action-panel-row {

          .archive-column {
            overflow: visible;
          }

          &:nth-child(even) {
            background-color: rgba(26, 49, 63, 0.23);
          }

          &:nth-child(odd) {
            background-color: rgba(26, 49, 63, 0.19);
          }
        }

        &.disabled-row {
          pointer-events: none;
        }
      }

      height: auto !important;
      padding-bottom: 0 !important;

      .react-bs-table-no-data {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }

    .react-bs-container-header.table-header-wrapper {
      z-index: 2;
      position: sticky;
      top: 0;
    }

    .react-bs-table-pagination {
      position: sticky;
      bottom: 0;
      margin: 0;
      padding: 10px;
      min-height: 50px;
      background-color: #FAFBFC;
      border-top: 1px solid #e9ecf0;

      .row {
        margin-top: 0 !important;
      }
      
      .caret {
        transform: rotate(180deg);
      }

      .dropdown-menu {
        top: auto;
        bottom: 100%;
      }

      .react-bootstrap-table-page-btns-ul {
        margin: 0;
      }
    }
  }
`
export const ResponsiveModal = styled(Modal)`
  width: ${props => {
    switch (props.size) {
      case 'small':
        return 'min(500px, calc(100vw - 32px)) !important;'
      case 'medium':
        return 'min(900px, calc(100vw - 32px)) !important;'
      default:
        return 'min(1400px, calc(98vw)) !important;'
    }
  }}
  max-height: 98vh !important;
  button.ant-modal-close {
    top: 0 !important;
    z-index: 1;
  }
  div.ant-modal-header {
    > .ant-modal-title {
      font-size: 16px;
      font-weight: 600;
    }
  }
  div.ant-modal-body {
    .rdtPicker {
      position: fixed;
    }
    overflow-y: auto;
    max-height: 80vh;
    ${props => {
    if (props.fixedheight) {
      switch (props.size) {
        case 'small':
          return 'height: min(400px, 80vh) !important;'
        case 'medium':
          return 'height: min(700px, 80vh) !important;'
        default:
          return 'height: min(1200px, 80vh) !important;'
      }
    }
    return null
  }};
    display: flex;
    flex-direction: column;
    background-color: transparent;
    }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }
  }
  &.default-height div.ant-modal-body{
    height: 80vh;
  }
  &.no-body-padding div.ant-modal-body{
    padding: 0;
  }
  &.medium {
    width: min(800px, calc(100vw - 32px)) !important;
  }
  .sticky {
    position: sticky;
    bottom: 0;
  }
  .btn-submit {
    box-shadow: rgb(9 9 9 / 50%) -1px 0px 10px;
  }
  .sort-attribues-panel {
    z-index: 1;
  }
`

export const TitleActionContainer = styled(Space)`
  font-size: 15px;
  color: #003B66;
`

export const Title = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: ${props => {
    switch (props.bottom) {
      case 'large':
        return '32px'
      case 'small':
        return '8px'
      default:
        return '24px'
    }
  }};
  color: #0a4f7a;
`

export const SubTitle = styled(Title)`
  margin-bottom: unset;
  font-size: 14px;
  color: #0a4f7aaa;
`

export const TitleWithActions = styled(Title)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
`

export const ChartNoDataAction = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0;
  z-index: 1;
  transition-duration: .25s;
  cursor: pointer;
  &:hover {
    opacity: .8;
    > * {
      opacity: 1;
      transform: scale(1);
    }
  }
  > * {
    opacity: 0;
    transform: scale(0);
  }
`

export const NoDataActionContent = styled.div`
  color: #0a4f7a;
  font-size: ${props => {
    switch (props.size) {
      case 'large':
        return '32px'
      case 'small':
        return '12px'
      default:
        return '21px'
    }
  }}
  ${props => props.bulky === 'false' && 'height: 0'};
 `

export const CustomFormItem = styled(Form.Item)`
  & .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    &::before {
      content: unset;
    }
    &::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
`

export const CustomPanel = styled(Panel)`
  &.panel-default {
    background-color: transparent;
    > .panel-footer {
      position: sticky;
      bottom: 0;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
`

export const ItemListingContainer = styled.div`
  padding: 1.5rem 0;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: ${props => {
    switch (props.margin) {
      case 'large':
        return '6rem'
      case 'medium':
        return '3rem'
      case 'none':
        return 'unset'
      default:
        return '1.5rem'
    }
  }};
  @media (min-width: 992px) {
    display: grid;
    gap: 2rem;
    ${(props) => `grid-template-columns: repeat(${props.columns || 6}, 1fr);`}
  }
`

export const FullSpaceSpin = styled(Spin)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const OverlayFullSpaceSpin = styled(FullSpaceSpin)`
  background-color: #ffffffaa;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
`

export const ButtonTextLeft = styled(Button)`
  &.ant-btn {
    text-align: left;
  }
`

export const FullWidthInput = styled(Input)`
  width: 100%;
`

export const FullWidthInputSearch = styled(Input.Search)`
  width: 100%;
`

const customEllipsis = {
  clickable: css`
    cursor: pointer;
  `,
  expanded: css`
    white-space: normal;
    -webkit-line-clamp: 100;
    max-height: 1000px;
    line-break: anywhere;
  `,
}

export const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.clickable && customEllipsis.clickable};
  ${props => props.expanded && customEllipsis.expanded};
`

export const VerticalForm = styled.form`
  & div.form-group {
    display: flex;
    flex-direction: column;
    & > * {
      width: 100% !important;
    }
    & > .control-label {
      text-align: left;
      padding-bottom: 7px;
      font-weight: 700;
    }
  }
`

export const ModalFormContainer = styled.div`
  padding: 0 32px;
`

export const AddItemContainer = styled(Flex)`
  justify-content: space-between;
  padding: 0 0 8px 0;
`

export const LabelInfo = styled.div`
  display: inline;
  font-size: 10px;
  vertical-align: text-top;
  margin-left: 4px;
`

export const PanelHeadingInner = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CustomLabel = styled.label`
  ${props => props.color && `color: ${props.color}`};
  ${props => props.weight && `font-weight: ${props.weight}`};
  ${props => props.size && `font-size: ${props.size} !important`};
`

export const BoundedText = styled.div`
  max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : 'initial'};
`

export const Required = css`
  &::after {
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    color: #ff4d4f;
    font-size: inherit;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`

export const CustomText = styled.div`
  transition-duration: .25s;
  font-weight: 600;
  font-size: 16px;
  color: ${props => props?.color || '#424242'};
  ${props => props.disabled && `opacity: .5`};
  ${props => props.required && Required};
`
