import React, { useCallback, useEffect, useState } from "react";
import { ResponsiveModal } from "../../../providers/StyleProvider/styles";
import { getSearchAttributes } from "../../../pages/RemoteObjectInstances/utils";
import { fetchROEExportData, fetchAllROEExports } from "../../../utils/appHelper";
import { FileExcelOutlined, LoadingOutlined } from '@ant-design/icons';
import { Divider, Space, Spin } from 'antd';
import { Flex } from "components";
import { Icon } from "components";

import Table from "../../UI/Table";
import { partialPathToFileInfo } from "../../../utils/fileUtils";
import { exportsTableColumns } from "./utils";
import { availableExportOptions } from "./contants";
import TwoStepDownloader from "./TwoStepDownloader";
import ExportError from "./ExportError";
import { __ } from '../../../utils/translationUtils'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const ExportModal = (props) => {
  const { open } = props

  const [fileExportInfo, setFileExportInfo] = useState(null)
  const [allExports, setAllExports] = useState(null)

  const getROEExports = useCallback((createdTo) => {
    return fetchAllROEExports({ createdTo }).then((res) => {
      const { data } = res
      setAllExports(data)
      return data
    })
  }, [])

  useEffect(() => {
    const createdTo = new Date().toISOString()
    getROEExports(createdTo)
    const interval = setInterval(() => {
      getROEExports(createdTo).then((data) => {
        if (data?.length === 0 || data?.every(({ status }) => status !== 'IN_PROGRESS')) {
          clearInterval(interval)
        }
      })
    }, 4000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (open !== availableExportOptions.onlyExports) {
      const { payloadData, commonProps } = props
      const { remoteObjectConfiguration, currentPagination, currentSearch, currentSort, visibleColumnsData } = payloadData || {}
      const { attributeList } = commonProps || {}

      const searchAttributes = getSearchAttributes(currentSearch, remoteObjectConfiguration, attributeList)
      const payload = {
        id: remoteObjectConfiguration.id,
        name: remoteObjectConfiguration.name,
        pageSize: currentPagination.pageSize,
        pageNumber: currentPagination.pageNumber,
        onlySelfOrders: remoteObjectConfiguration.onlySelfOrders,
        displayAttributes: visibleColumnsData,
        sortAttributes: currentSort.filter(({ enabled }) => enabled == null || enabled),
        settings: remoteObjectConfiguration?.settings,
        objectTypeId: remoteObjectConfiguration.objectTypeId,
        systemId: remoteObjectConfiguration.systemId,
        searchAttributes
      }
      if (open === availableExportOptions.total) payload.returnFullSet = true

      fetchROEExportData(payload)
        .then((res) => {
          res && setFileExportInfo(partialPathToFileInfo(res.headers?.["x-location"]))
        }).catch((err) => {
          setFileExportInfo({ status: 'error' })
        })
    }
  }, [])

  const { objectName, onClose } = props

  return (
    <ResponsiveModal
      title={open !== availableExportOptions.onlyExports ? __("data_exports") : __("exports")}
      visible={open}
      fixedheight={true}
      size="medium"
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Space direction="vertical">
        {
          open !== availableExportOptions.onlyExports && (
            <>
              <Flex justifyContent='center'>
                {
                  fileExportInfo ?
                    fileExportInfo.status === 'error' ?
                      (
                        <ExportError />
                      ) : (
                        <TwoStepDownloader
                          fileInfo={fileExportInfo}
                          downloadButtonProps={{
                            items:
                              [
                                { title: 'Excel', option: 'xlsx', icon: <FileExcelOutlined style={{ color: "green" }} /> },
                                { title: 'CSV', option: 'csv', icon: <Icon component="csv" color="blue" /> }
                              ]
                        }}
                        />
                      ) :
                    <Spin indicator={antIcon} />
                }
              </Flex>
              <Divider
                orientation="left"
                direction="horizontal" >
                {__('Last Exports')}
              </Divider>
            </>
          )
        }
        {
          <Table
            loading={allExports === null}
            columns={exportsTableColumns}
            data={allExports}
          />
        }
      </Space>
    </ResponsiveModal>
  )
}

export default ExportModal
